<template>
  <b-overlay
    :show="stateOverlayForCanvas || stateOverlayForKO"
    id="previous-canvas"
  >
    <CanvasHeader
      :pageType="'previousCanvas'"
      :commitKO="commitKO"
      :deleteCanvas="deleteCanvas"
    />

    <img
      class="previous-canvas__img-top"
      src="/assets/for-items-top.png"
      alt="Add New Thought"
      @click="toggleNewThought"
    />

    <div v-if="previousCanvas.canvasName" class="previous-canvas__container">
      <div class="previous-canvas__list">
        <Vue3DraggableResizable
          v-for="item in previousCanvas.KOs"
          :key="item.knowledgeObjectId"
          :initW="0"
          :initH="0"
          :draggable="true"
          :resizable="false"
          :active="true"
          :x="convertToPixeles(getPositionForKO(item).x, 0).x"
          :y="convertToPixeles(0, getPositionForKO(item).y).y"
          @dragging="movedKO"
          @drag-end="dragStop(item.knowledgeObjectId)"
          class="previous-canvas__list-item"
          :parent="true"
        >
          <KOItem
            :body="item.body"
            :deleteKO="deleteKo"
            :updateKo="updateKO"
            :metatags="item.metatags"
            type="blank-canvas"
            :knowledge-object-id="item.knowledgeObjectId"
          />
        </Vue3DraggableResizable>
      </div>

      <TextEntryBox
        v-if="isOpenAddingBlock"
        type="previousCanvas"
        :KOForUpdate="KOForUpdate"
        :previousCanvasId="canvasId"
        :isCommitKO="isCommitKO"
        :addingBlockPosition="addingBlockPosition"
        ref="textEntryBoxRef"
      />

      <ul class="previous-canvas__link-list">
        <li class="link-item">
          <template v-if="isEditingCanvasName">
            <input
              type="text"
              v-model="canvasNameDraft"
              ref="canvasNameInput"
              @keydown.enter.prevent="saveCanvasName"
              @blur="saveCanvasName"
              class="parking-input"
            />
          </template>
          <template v-else>
            <button class="parking-btn" @click="editCanvasName">
              {{ previousCanvas.canvasName }}
            </button>
          </template>
        </li>
      </ul>
    </div>

    <div
      id="darken-block"
      v-if="isOpenNewThought"
      @click="toggleNewThought"
    ></div>
    <NewLooseThought
      v-if="isOpenNewThought"
      class="previous-canvas__new-thought-dropdown"
      :toggleNewThought="toggleNewThought"
    />
  </b-overlay>
</template>

<script>
  import { mapGetters } from "vuex";
  import TextEntryBox from "@/components/TextEntryBox.vue";
  import Vue3DraggableResizable from "vue3-draggable-resizable";
  import CanvasHeader from "@/components/PreviousBlankHeader.vue";
  import NewLooseThought from "@/components/NewLooseThoughts.vue";
  import KOItem from "@/components/KOItem.vue";

  import {
    convertToPercentage,
    convertToPixeles,
    getItemFromLS,
    getPositionForKO,
    setItemToLS,
    updatePositionKo,
  } from "@/functions";

  export default {
    name: "PreviousCanvas",
    components: {
      TextEntryBox,
      CanvasHeader,
      NewLooseThought,
      Vue3DraggableResizable,
      KOItem,
    },
    data() {
      return {
        isPossibilityUpdateKo: true,
        isOpenAddingBlock: false,
        addingBlockPosition: { top: null, left: null },
        isOpenNewThought: false,
        KOForUpdate: {},
        isCommitKO: false,
        top: 0,
        left: 0,
        isDeletingCanvas: false,
        movedItem: false,
        canvasInterval: null,
        isEditingCanvasName: false,
        canvasNameDraft: "",
      };
    },
    computed: {
      ...mapGetters({
        previousCanvas: "canvasStore/previousCanvas",
        createdKOId: "KOStore/createdKOId",
        stateOverlayForCanvas: "canvasStore/stateOverlayForCanvas",
        stateOverlayForKO: "KOStore/stateOverlayForKO",
      }),

      // Extract canvasId into a computed property for clarity
      canvasId() {
        return this.$route.params.id;
      },
    },
    created() {
      window.addEventListener("beforeunload", this.updateCanvas);
    },
    mounted() {
      // Use $el to attach event listener instead of document.getElementById
      this.$el.addEventListener("click", this.onCanvasClick);

      this.$store.dispatch("canvasStore/getCanvas", this.canvasId);

      // Auto-update canvas every 5 minutes (300000 ms)
      const updateInterval = 300000;
      this.canvasInterval = setInterval(() => {
        this.updateCanvas();
      }, updateInterval);
    },
    watch: {
      createdKOId(newVal) {
        // When a new KO is created, add it to the current canvas
        if (newVal) {
          this.updateKoOnCanvas(newVal, "add");
        }
      },
    },
    methods: {
      setItemToLS,
      convertToPixeles,
      convertToPercentage,
      getPositionForKO,

      onCanvasClick(e) {
        const target = e.target;

        const isDeleteButton = target.closest(".KO-list__btn-delete");
        if (isDeleteButton) {
          this.isOpenAddingBlock = false;
          return;
        }

        if (this.isClickOkayForOpenTextEntryBox(e)) {
          // Calculate position for TextEntryBox
          this.addingBlockPosition.top = e.clientY;
          this.addingBlockPosition.left = e.clientX;
          this.toggleAddingBlock();
          this.isOpenNewThought = false;
        }

        if (
          e.target.className &&
          e.target.className.indexOf("main-adding-block__save") !== -1
        ) {
          this.isOpenAddingBlock = false;
        }

        this.isPossibilityUpdateKo = true;
      },

      toggleAddingBlock() {
        this.isOpenAddingBlock = true;
        this.commitNewKoIfExists();
      },

      commitNewKoIfExists() {
        if (
          this.$refs.textEntryBoxRef &&
          (this.$refs.textEntryBoxRef.textAreaValue ||
            this.$refs.textEntryBoxRef.metatags)
        ) {
          this.$refs.textEntryBoxRef.commitKO(
            this.$refs.textEntryBoxRef.textAreaValue,
            this.$refs.textEntryBoxRef.metatags
          );
          this.isOpenAddingBlock = false;
        }
      },

      async deleteCanvas() {
        try {
          await this.$store.dispatch(
            "KOStore/deleteKOItems",
            this.previousCanvas.KOs
          );
          await this.$store.dispatch("canvasStore/deleteCanvas", this.canvasId);
          this.isDeletingCanvas = true;
          await this.$router.push("/");
        } catch (err) {
          console.error("Error deleting canvas:", err);
        }
      },

      editCanvasName() {
        this.canvasNameDraft = this.previousCanvas.canvasName;
        this.isEditingCanvasName = true;

        this.$nextTick(() => {
          if (this.$refs.canvasNameInput) {
            this.$refs.canvasNameInput.focus();
            const length = this.canvasNameDraft.length;
            this.$refs.canvasNameInput.setSelectionRange(length, length);
          }
        });
      },

      async saveCanvasName() {
        if (!this.canvasNameDraft.trim()) {
          this.isEditingCanvasName = false;
          this.canvasNameDraft = this.previousCanvas.canvasName;
          return;
        }

        this.previousCanvas.canvasName = this.canvasNameDraft;
        this.isEditingCanvasName = false;

        try {
          const updatedCanvas = {
            canvasName: this.canvasNameDraft,
            koUpdates: [],
            koLocations: JSON.stringify(getItemFromLS("koLocations")),
          };

          setItemToLS("updatedCanvas", updatedCanvas);

          await this.$store.dispatch(
            "canvasStore/updateCanvas",
            this.previousCanvas.userCanvasId
          );
        } catch (err) {
          console.error("error updating: ", err);
        }
      },

      async updateKoOnCanvas(id, updateType) {
        try {
          const updatedCanvas = {
            canvasName: this.previousCanvas.canvasName,
            koUpdates: [{ id, updateType }],
            koLocations: JSON.stringify(getItemFromLS("koLocations")),
          };
          setItemToLS("updatedCanvas", updatedCanvas);
          await this.$store.dispatch("canvasStore/updateCanvas", this.canvasId);
        } catch (err) {
          console.error("Error updating KO on canvas:", err);
        }
      },

      async updateCanvas() {
        if (this.isDeletingCanvas) return; // No need to update if we are deleting the canvas

        try {
          const updatedCanvas = {
            canvasName: this.previousCanvas.canvasName,
            koUpdates: [],
            koLocations: JSON.stringify(getItemFromLS("koLocations")),
          };
          setItemToLS("updatedCanvas", updatedCanvas);
          await this.$store.dispatch(
            "canvasStore/updateCanvas",
            this.previousCanvas.userCanvasId
          );
          setItemToLS("KOItems", []);
          this.$store.commit("KOStore/setKOItems", []);
        } catch (err) {
          console.error("Error updating canvas:", err);
        }
      },

      commitKO() {
        // This flag is turned on when user commits a KO, might be used to control TextEntryBox behavior
        this.isCommitKO = true;
      },

      updateKO(itemKO) {
        const koLocations = getItemFromLS("koLocations") || [];
        const itemPosition = koLocations.find(
          (el) => el.id === itemKO.knowledgeObjectId
        ) || { x: 0, y: 0 };

        if (itemPosition.x != null && itemPosition.y != null) {
          this.top = itemPosition.y;
          this.left = itemPosition.x;
          itemKO.left = itemPosition.x;
          itemKO.top = itemPosition.y;
          this.addingBlockPosition = {
            left: this.convertToPixeles(itemPosition.x + 0.8, 0).x,
            top: this.convertToPixeles(0, itemPosition.y + 14.2).y,
          };
        }

        this.KOForUpdate = itemKO;
        this.isOpenAddingBlock = !this.isOpenAddingBlock;

        // Clear KOForUpdate after a delay
        setTimeout(() => {
          this.KOForUpdate = {};
        }, 500);
      },

      async deleteKo(id) {
        try {
          await this.$store.dispatch("KOStore/deleteKOItem", id);
          // Remove the deleted KO from the local previousCanvas object
          this.previousCanvas.KOs = this.previousCanvas.KOs.filter(
            (el) => el.knowledgeObjectId !== id
          );

          let koLocations = getItemFromLS("koLocations") || [];
          koLocations = koLocations.filter((el) => el.id !== id);
          setItemToLS("koLocations", koLocations);

          // Update the canvas to reflect removal of KO
          this.updateKoOnCanvas(id, "remove");
        } catch (err) {
          console.error("Error deleting KO:", err);
        }
      },

      movedKO(newRect) {
        // Track the KO movement while dragging
        this.top = newRect.y;
        this.left = newRect.x;
        this.movedItem = true;
      },

      dragStop(knowledgeObjectId) {
        const position = this.convertToPercentage(this.left, this.top);
        const updateKO = this.previousCanvas.KOs.find(
          (elem) => String(elem.knowledgeObjectId) === String(knowledgeObjectId)
        );

        if (updateKO && this.isPossibilityUpdateKo && this.movedItem) {
          updatePositionKo(knowledgeObjectId, position);
          const newKo = {
            y: position.y,
            x: position.x,
            body: updateKO.body,
            metatags: updateKO.metatags,
          };
          setItemToLS("updatedKO", newKo);
        }

        this.movedItem = false;
        this.isPossibilityUpdateKo = false;
      },

      toggleNewThought() {
        this.isOpenNewThought = !this.isOpenNewThought;
        if (this.isOpenAddingBlock) this.isOpenAddingBlock = false;
      },

      isClickOkayForOpenTextEntryBox(e) {
        const className = e.target.className || "";
        return (
          (className.indexOf("previous-canvas") !== -1 ||
            className.indexOf("main-adding-block__save") !== -1) &&
          className.indexOf("__container") === -1 &&
          className.indexOf("__img-top") === -1
        );
      },
    },
    beforeUnmount() {
      if (!this.isDeletingCanvas) {
        this.updateCanvas();
      }
      clearInterval(this.canvasInterval);
      window.removeEventListener("beforeunload", this.updateCanvas);
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/pages/home/previous-canvas.scss";
</style>

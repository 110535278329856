<template>
  <div class="background-sign-in">
    <div class="sign-in">
      <div class="sign-in__left-part">
        <img
          src="/assets/logo.png"
          alt="logo"
          width="80"
          class="sign-in__left-part-logo"
        />
        <div class="sign-in__left-part-title">LocusVMS</div>
        <p class="sign-in__left-part-text-short">Venue Management System</p>
        <p class="sign-in__left-part-text-long">
          A powerful, yet easy-to-use application for managing venue geospatial
          data.
        </p>
      </div>

      <div class="sign-in__right-part">
        <div class="login-wrap">
          <div class="login-html">
            <!-- Tabs -->
            <input id="tab-1" type="radio" name="tab" class="sign-in" checked />
            <label for="tab-1" class="tab">Sign In</label>
            <input id="tab-2" type="radio" name="tab" class="sign-up" />
            <label for="tab-2" class="tab">Sign Up</label>

            <div class="login-form">
              <!-- Sign In Form -->
              <form class="sign-in-htm" @submit.prevent="onSubmitSignIn">
                <div class="group">
                  <label for="email-login" class="label">Email</label>
                  <input
                    id="email-login"
                    type="text"
                    class="input"
                    v-model.trim="signIn.username"
                  />
                </div>
                <div class="group">
                  <label for="pass-login" class="label">Password</label>
                  <input
                    id="pass-login"
                    type="password"
                    class="input"
                    v-model.trim="signIn.password"
                  />
                </div>
                <div class="group">
                  <input
                    id="check"
                    type="checkbox"
                    class="check"
                    v-model="signIn.keepSignedIn"
                  />
                  <label for="check" class="check-label">
                    <input type="checkbox" class="check-label-box" checked />
                    <div>Keep me Signed in</div>
                  </label>
                </div>

                <div class="group">
                  <button type="submit" class="button" :disabled="loading">
                    <div v-if="loading" class="loading-spinner"></div>
                    <span v-else>Sign In</span>
                  </button>
                </div>

                <div v-if="showLoginError" class="error">
                  {{ errmsg }}
                </div>

                <div class="hr"></div>
                <div class="foot-lnk">
                  <a class="button" @click.prevent="onNavigateForgotPassword"
                    >Forgot Password?</a
                  >
                </div>
              </form>

              <!-- Sign Up Form -->
              <form class="sign-up-htm" @submit.prevent="onSubmitSignUp">
                <div class="group">
                  <label for="email" class="label">Email Address</label>
                  <input
                    id="email"
                    type="text"
                    class="input"
                    v-model.trim="signUp.email"
                    @input="clearRegisterError"
                  />
                  <p v-if="signUp.email && !isEmailValid" class="error">
                    Email is not valid
                  </p>
                </div>
                <div class="group">
                  <label for="user-register" class="label">Username</label>
                  <input
                    id="user-register"
                    type="text"
                    class="input"
                    v-model.trim="signUp.username"
                    @input="clearRegisterError"
                  />
                </div>
                <div class="group">
                  <label for="pass-register" class="label">Password</label>
                  <input
                    id="pass-register"
                    type="password"
                    class="input"
                    v-model.trim="signUp.password"
                    @input="clearRegisterError"
                  />
                  <p v-if="signUp.password && !isPasswordValid" class="error">
                    Password must be at least 8 characters
                  </p>
                </div>
                <div class="group">
                  <label for="repeat-pass" class="label">Repeat Password</label>
                  <input
                    id="repeat-pass"
                    type="password"
                    class="input"
                    v-model.trim="signUp.confirmPassword"
                    @input="clearRegisterError"
                  />
                  <p
                    v-if="signUp.confirmPassword && !doPasswordsMatch"
                    class="error"
                  >
                    Passwords do not match.
                  </p>
                </div>

                <div class="group">
                  <button
                    type="submit"
                    class="button"
                    :disabled="loading || !isFormValid"
                  >
                    <div v-if="loading" class="loading-spinner"></div>
                    <span v-else>Sign Up</span>
                  </button>
                </div>
                <div v-if="showRegisterError" class="error">
                  {{ errmsg }}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { router } from "@/router/index";
  import * as config from "@/config";
  var AmazonCognitoIdentity = require("amazon-cognito-identity-js");

  var userPool = [];
  var attributeList = [];
  var dataEmail = {
    Name: "email",
    Value: "",
  };

  export default {
    name: "SignIn",
    data() {
      return {
        signIn: {
          username: "",
          password: "",
          keepSignedIn: true,
        },
        signUp: {
          username: "",
          password: "",
          confirmPassword: "",
          email: "",
        },
        showRegisterError: false,
        showLoginError: false,
        errmsg: "",
        loading: false,
      };
    },
    computed: {
      isEmailValid() {
        return this.signUp.email && this.validateEmail(this.signUp.email);
      },
      isPasswordValid() {
        return this.signUp.password && this.signUp.password.length >= 8;
      },
      doPasswordsMatch() {
        return this.signUp.password === this.signUp.confirmPassword;
      },
      isFormValid() {
        return (
          this.isEmailValid && this.isPasswordValid && this.doPasswordsMatch
        );
      },
    },
    methods: {
      validateEmail(email) {
        // eslint-disable-next-line no-useless-escape
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(email);
      },
      clearRegisterError() {
        this.showRegisterError = false;
      },
      onNavigateForgotPassword() {
        router.push("/forgot-password");
      },
      async onSubmitSignUp() {
        if (!config.adminEmailList.includes(this.signUp.email)) {
          this.showRegisterError = true;
          this.errmsg = "The email has not been approved by the admin.";
          return;
        }

        this.errmsg = "";
        dataEmail.Value = this.signUp.email;
        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
          dataEmail
        );
        attributeList.push(attributeEmail);

        userPool = new AmazonCognitoIdentity.CognitoUserPool(config.poolData);

        this.loading = true;

        userPool.signUp(
          this.signUp.email,
          this.signUp.password,
          attributeList,
          null,
          (err, result) => {
            this.loading = false;
            this.showRegisterError = false;

            if (err) {
              this.showRegisterError = true;
              this.errmsg = err.message;
            } else {
              const userId = result.userSub;

              const userData = {
                username: this.signUp.username,
                password: this.signUp.password,
                email: this.signUp.email,
                userId: userId,
              };

              this.$store.commit("authStore/updateState", {
                userData: userData,
              });
              router.push("/confirm-email");
            }
          }
        );
      },
      onSubmitSignIn() {
        const authenticationData = {
          Username: this.signIn.username,
          Password: this.signIn.password,
        };
        const authenticationDetails =
          new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        const userPool = new AmazonCognitoIdentity.CognitoUserPool(
          config.poolData
        );

        const userData = {
          Username: this.signIn.username,
          Pool: userPool,
        };

        this.loading = true;
        this.showLoginError = false;

        const userAuth = new AmazonCognitoIdentity.CognitoUser(userData);
        userAuth.authenticateUser(authenticationDetails, {
          onSuccess: (session) => {
            this.loading = false;

            const accessToken = session.getAccessToken().getJwtToken();
            const idToken = session.getIdToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();

            this.$store.dispatch("authStore/setTokens", {
              accessToken,
              idToken,
              refreshToken,
            });

            router.push("/");
          },
          onFailure: (err) => {
            this.loading = false;
            this.showLoginError = true;
            this.errmsg = err.message;
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/pages/auth/sign-in.scss";
</style>

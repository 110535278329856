import { createRouter, createWebHistory } from "vue-router";
import MainMenu from "@/pages/home/MainMenu.vue";
import SignIn from "@/pages/auth/SignIn.vue";
import BlankCanvas from "@/pages/home/BlankCanvas.vue";
import PreviousCanvas from "@/pages/home/PreviousCanvas.vue";
import LooseThoughts from "@/pages/home/LooseThoughts.vue";
import ConfirmEmail from "@/pages/auth/ConfirmEmail.vue";
import ForgotPassword from "@/pages/auth/ForgotPassword.vue";
import ResetPassword from "@/pages/auth/ResetPassword.vue";
import Settings from "@/pages/home/SettingPage.vue";
import { getItemFromLS } from "@/functions";

const routes = [
  {
    path: "/",
    name: "MainMenu",
    component: MainMenu,
    beforeEnter: (to, from, next) => {
      const hasAccessToken = !!getItemFromLS("access_token");
      const hasCodeQuery = !!to.query.code;

      if (!hasAccessToken && !hasCodeQuery) {
        next({ name: "SignIn" });
      } else {
        next();
      }
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/blank-canvas",
    name: "BlankCanvas",
    component: BlankCanvas,
  },
  {
    path: "/previous-canvas/:id",
    name: "PreviousCanvas",
    component: PreviousCanvas,
  },
  {
    path: "/loose-thoughts",
    name: "LooseThoughts",
    component: LooseThoughts,
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: ConfirmEmail,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem("previousUrl", from.fullPath);
  next();
});

import canvasStore from "./canvasStore";
import KOStore from "./KOStore";
import thoughtsStore from "./thoughtsStore";
import authStore from "./authStore";
import settingStore from "./settingStore";
import { createStore } from "vuex";

export const store = createStore({
  modules: {
    canvasStore,
    KOStore,
    thoughtsStore,
    authStore,
    settingStore,
  },
});

export default store;

const initialState = {
  fontSize: 14,
  fontColor: "#000000",
  fontFamily: "Arial",
};

export const state = () => ({
  ...initialState,
});

export const mutations = {
  SET_FONT_SIZE(state, fontSize) {
    state.fontSize = fontSize;
  },
  SET_FONT_COLOR(state, fontColor) {
    state.fontColor = fontColor;
  },
  SET_FONT_FAMILY(state, fontFamily) {
    state.fontFamily = fontFamily;
  },
};

export const actions = {
  updateFontSize({ commit }, fontSize) {
    commit("SET_FONT_SIZE", fontSize);
  },
  updateFontColor({ commit }, fontColor) {
    commit("SET_FONT_COLOR", fontColor);
  },
  updateFontFamily({ commit }, fontFamily) {
    commit("SET_FONT_FAMILY", fontFamily);
  },
};

export const getters = {
  fontSize: (state) => state.fontSize,
  fontColor: (state) => state.fontColor,
  fontFamily: (state) => state.fontFamily,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <CanvasHeader :disableMenu="true" />
  <div class="settings-page">
    <h1>Settings</h1>
    <form @submit.prevent="applySettings">
      <div class="form-group">
        <label for="fontSize">Font Size:</label>
        <input
          type="number"
          id="fontSize"
          v-model="localSettings.fontSize"
          min="10"
          max="50"
        />
      </div>
      <div class="form-group">
        <label for="fontColor">Font Color:</label>
        <input type="color" id="fontColor" v-model="localSettings.fontColor" />
      </div>
      <!-- <div class="form-group">
        <label for="fontFamily">Font Family:</label>
        <select id="fontFamily" v-model="localSettings.fontFamily">
          <option value="Arial">Arial</option>
          <option value="Verdana">Verdana</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Courier New">Courier New</option>
        </select>
      </div> -->
      <button type="submit">Apply</button>
    </form>
    <div class="preview" :style="previewStyle">
      <p>Preview Text</p>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import CanvasHeader from "@/components/PreviousBlankHeader.vue";
  export default {
    components: {
      CanvasHeader,
    },
    data() {
      return {
        localSettings: {
          fontSize: this.fontSize,
          fontColor: this.fontColor,
          fontFamily: this.fontFamily,
        },
      };
    },
    computed: {
      ...mapGetters({
        fontSize: "settingStore/fontSize",
        fontColor: "settingStore/fontColor",
        fontFamily: "settingStore/fontFamily",
      }),
      previewStyle() {
        return {
          fontSize: `${this.localSettings.fontSize}px`,
          color: this.localSettings.fontColor,
          fontFamily: this.localSettings.fontFamily,
        };
      },
    },
    methods: {
      ...mapActions("settingStore", [
        "updateFontSize",
        "updateFontColor",
        "updateFontFamily",
      ]),
      applySettings() {
        this.updateFontSize(this.localSettings.fontSize);
        this.updateFontColor(this.localSettings.fontColor);
        this.updateFontFamily(this.localSettings.fontFamily);
        console.log("Settings applied:", this.localSettings);
      },
    },
  };
</script>

<style scoped>
  .settings-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  button {
    padding: 10px 20px;
    background-color: #4a5568;
    color: #f7fafc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  button:hover {
    background-color: #2d3748;
  }

  .preview {
    margin-top: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
</style>

<template>
  <div class="KO-list" @click="updateKo(item)">
    <p class="KO-list__item-body">{{ this.body }}</p>
    <hr class="separation-strip" />
    <ul class="KO-list__metatags">
      <li
        class="KO-list__metatags-item"
        v-for="hash in this.metatags"
        :key="hash.index"
      >
        {{ hash }}
      </li>
    </ul>
    <!-- <div class="KO-list__btn KO-list__btn-update">
      <i
        class="bi bi-pencil-square"
        style="font-size: 1rem; color: #343a40; cursor: pointer"
        @click="updateKo(item)"
      ></i>
    </div> -->
    <button
      type="button"
      class="KO-list__btn KO-list__btn-delete"
      @click="deleteKO(item.knowledgeObjectId)"
    />
  </div>
</template>

<script>
  import KODetails from "@/components/KODetails.vue";

  export default {
    name: "KOItem",
    props: {
      body: String,
      metatags: Array,
      knowledgeObjectId: String,
      type: String,
      //FUNCTIONS
      deleteKO: Function,
      updateKo: Function,
    },
    data() {
      return {
        item: {
          body: this.body,
          metatags: this.metatags,
          knowledgeObjectId: this.knowledgeObjectId,
        },
        modalShow: false,
      };
    },
    components: {
      KODetails,
    },
    beforeUpdate() {
      this.item = {
        body: this.body,
        metatags: this.metatags,
        knowledgeObjectId: this.knowledgeObjectId,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/components/ko-item.scss";
</style>

<template>
  <div class="background">
    <div class="main-container">
      <div class="branding">
        <img src="/assets/logo.png" alt="logo" class="logo" />
        <h2 class="title">LocusVMS</h2>
        <p class="description short">Venue Management System</p>
        <p class="description long">
          A powerful, yet easy-to-use application for managing venue geospatial
          data.
        </p>
      </div>
      <div class="form-container">
        <h3 class="form-title">Forgot Password</h3>
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label for="forgot-email">Email</label>
            <input
              id="forgot-email"
              type="text"
              class="input-field"
              v-model="email"
            />
          </div>
          <button type="submit" class="submit-button" :disabled="loading">
            <div v-if="loading" class="loading-spinner"></div>
            <span v-else>Submit</span>
          </button>
          <div v-if="showError" class="error">
            {{ errMsg }}
          </div>
        </form>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { router } from "@/router/index";
  import * as config from "@/config";
  const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

  export default {
    name: "ForgotPassword",
    data() {
      return {
        email: "",
        loading: false,
        showError: false,
        errMsg: "",
      };
    },
    methods: {
      onSubmit() {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(
          config.poolData
        );
        const userData = {
          Username: this.email,
          Pool: userPool,
        };

        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        this.showError = false;
        this.errMsg = "";
        this.loading = true;

        cognitoUser.forgotPassword({
          onSuccess: (data) => {
            this.loading = false;
            console.log("Forgot password initiated:", data);
          },
          onFailure: (err) => {
            this.loading = false;
            this.showError = true;
            this.errMsg = this.getErrorMessage(err.code);
            console.error("Forgot password error:", err);
          },
          inputVerificationCode: (data) => {
            this.loading = false;
            console.log("Code sent:", data);
            this.$store.state.username = this.email;
            router.push("/reset-password");
          },
        });
      },
      getErrorMessage(code) {
        switch (code) {
          case "CodeMismatchException":
            return "Invalid verification code provided";
          case "NotAuthorizedException":
            return "The user has already been confirmed";
          case "UserNotFoundException":
            return "Username (email) not found!";
          case "LimitExceededException":
            return "Attempt limit exceeded, please try after some time";
          case "UserNotConfirmedException":
            return "User registration not confirmed";
          default:
            return "An error has occurred!";
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/pages/auth/forgot-password.scss";
</style>

<template>
  <b-overlay :show="showOverlay" id="blank-canvas">
    <CanvasHeader
      pageType="blankCanvas"
      :clearTheCanvas="clearTheCanvas"
      :commitKO="commitKO"
      :addName="handleAddName"
      :saveBlankCanvas="saveNewCanvas"
      :isParkingBlank="!isNewBlankCanvas"
      :inputName="inputName"
    />

    <img
      class="blank-canvas__img-top"
      src="/assets/for-items-top.png"
      @click="toggleNewThought"
    />
    <div
      id="darken-block"
      v-if="isOpenNewThought"
      @click="toggleNewThought"
    ></div>
    <NewLooseThought
      v-if="isOpenNewThought"
      class="blank-canvas__new-thought-dropdown"
      :toggleNewThought="toggleNewThought"
    />

    <div id="body-element">
      <div id="blank-canvas__KO-list">
        <Vue3DraggableResizable
          v-for="item in KOItems"
          :key="item.knowledgeObjectId"
          :initW="0"
          :initH="0"
          :draggable="true"
          :resizable="false"
          :active="true"
          :x="convertToPixeles(getPositionForKO(item).x, 0).x"
          :y="convertToPixeles(0, getPositionForKO(item).y).y"
          @dragging="movedKO"
          @drag-end="dragStop(item.knowledgeObjectId)"
          class="KO-list-item"
          :parent="true"
        >
          <KOItem
            :body="item.body"
            :deleteKO="deleteKo"
            :updateKo="updateKO"
            :metatags="item.metatags"
            type="blank-canvas"
            :knowledge-object-id="item.knowledgeObjectId"
          />
        </Vue3DraggableResizable>
      </div>

      <TextEntryBox
        v-if="isOpenAddingBlock"
        :type="type"
        :isCommitKO="isCommitKO"
        :KOForUpdate="KOForUpdate"
        :addingBlockPosition="addingBlockPosition"
        ref="textEntryBoxRef"
      />
    </div>

    <div class="link">
      <ul class="link-list">
        <li class="link-list__item">
          <input
            v-if="!isEnteringName"
            class="parking-input"
            type="button"
            :value="
              isNewBlankCanvas
                ? 'Enter Name for Blank Canvas'
                : 'Create New Canvas'
            "
            @click="handleAddName"
          />
          <input
            v-else
            type="text"
            class="parking-input"
            v-model="inputName"
            ref="input_canvas_name"
            @keydown.enter="handleSaveNewCanvas"
          />
        </li>

        <li
          class="link-list__item"
          v-for="canvas in blankCanvases"
          :key="canvas.userCanvasId"
          @click="showSelectBlankCanvas(canvas)"
        >
          <p
            class="link-list__item-name"
            :class="{ 'is-select-canvas': isCanvasSelected(canvas) }"
          >
            {{ canvas.canvasName }}
          </p>
        </li>
      </ul>
    </div>
  </b-overlay>
</template>

<script>
  import { mapGetters } from "vuex";
  import TextEntryBox from "@/components/TextEntryBox.vue";
  import CanvasHeader from "@/components/PreviousBlankHeader.vue";
  import NewLooseThought from "@/components/NewLooseThoughts.vue";
  import KOItem from "@/components/KOItem.vue";
  import Vue3DraggableResizable from "vue3-draggable-resizable";

  import {
    convertToPercentage,
    convertToPixeles,
    filterKOPosition,
    getItemFromLS,
    getPositionForKO,
    setItemToLS,
    updatePositionKo,
  } from "@/functions";

  export default {
    name: "BlankCanvas",
    components: {
      TextEntryBox,
      CanvasHeader,
      NewLooseThought,
      KOItem,
      Vue3DraggableResizable,
    },
    data() {
      return {
        isPossibilityUpdateKo: false,
        isOpenAddingBlock: false,
        isCommitKO: false,
        KOForUpdate: {},
        isEnteringName: false,
        inputName: "",
        isOpenNewThought: false,
        addingBlockPosition: { top: 240, left: 450 },
        top: 0,
        left: 0,
        isNewBlankCanvas: true,
        selectCanvasName: null,
        idSelectBlankCanvas: "",
        showModalSaveCanvas: false,
        type: "blankCanvas",
        movedItem: false,
      };
    },
    computed: {
      ...mapGetters({
        newCanvas: "canvasStore/newCanvas",
        blankCanvases: "canvasStore/blankCanvases",
        KOItems: "KOStore/KOItems",
        stateOverlayForCanvas: "canvasStore/stateOverlayForCanvas",
        stateOverlayForKO: "KOStore/stateOverlayForKO",
        parkCanvas: "canvasStore/previousCanvas",
        currentThoughtId: "thoughtsStore/currentThoughtId",
      }),

      showOverlay() {
        return this.stateOverlayForCanvas || this.stateOverlayForKO;
      },
    },
    watch: {
      blankCanvases() {
        this.isOpenAddingBlock = true;
      },
      KOItems() {
        this.isOpenAddingBlock = false;
      },
      parkCanvas(newVal) {
        if (newVal && newVal.userCanvasId === this.idSelectBlankCanvas) {
          this.$store.commit("KOStore/setKOItems", newVal.KOs);
        }
      },
    },
    mounted() {
      if (this.newCanvas && (this.newCanvas.body || this.newCanvas.metatags)) {
        this.toggleAddingBlock();
      }

      const canvasPlace = this.$el;
      canvasPlace.addEventListener("click", this.onCanvasClick);

      const previousUrl = sessionStorage.getItem("previousUrl");
      if (previousUrl !== "/loose-thoughts") {
        this.$store.commit("thoughtsStore/setCurrentThoughtId", "");
      }
    },
    beforeUnmount() {
      if (!this.isNewBlankCanvas) {
        this.updateCanvas(this.selectCanvasName);
      }
    },
    methods: {
      getItemFromLS,
      setItemToLS,
      convertToPixeles,
      convertToPercentage,
      getPositionForKO,
      filterKOPosition,
      updatePositionKo,

      onCanvasClick(e) {
        const target = e.target;

        const isDeleteButton = target.closest(".KO-list__btn-delete");
        if (isDeleteButton) {
          this.isOpenAddingBlock = false;
          return;
        }

        const correctX = 20;
        const correctY = 60;

        if (this.shouldOpenTextEntryBox(e)) {
          if (this.isEnteringName && this.inputName === "")
            this.isEnteringName = false;
          this.addingBlockPosition.top = e.clientY - correctY;
          this.addingBlockPosition.left = e.clientX - correctX;
          this.openTextEntryBox();
        }

        if (
          e.target.className &&
          e.target.className.indexOf("main-adding-block__save") !== -1
        ) {
          this.isOpenAddingBlock = false;
        }

        this.isPossibilityUpdateKo = true;
      },

      shouldOpenTextEntryBox(e) {
        const targetId = e.target.id || "";
        const targetClass = e.target.className || "";
        return (
          targetId === "blank-canvas" ||
          targetId === "blank-canvas__KO-list" ||
          targetClass.indexOf("loose-thoughts ") !== -1
        );
      },

      handleSaveNewCanvas() {
        if (this.inputName.trim() !== "") {
          this.saveNewCanvas();
        }
      },

      openTextEntryBox() {
        this.toggleAddingBlock();
        this.isOpenNewThought = false;
      },

      toggleAddingBlock() {
        this.isOpenAddingBlock = true;
        this.commitNewKoIfExists();
      },

      handleAddName() {
        this.type = "blankCanvas";

        if (this.isNewBlankCanvas) {
          this.commitNewKoIfExists();
          this.isEnteringName = true;
          this.$nextTick(() => {
            if (this.$refs.input_canvas_name) {
              this.$refs.input_canvas_name.focus();
            }
          });
          if (this.inputName.length) this.saveNewCanvas();
        } else {
          this.updateExistingCanvasAndClear();
        }
      },

      commitNewKoIfExists() {
        if (
          this.$refs.textEntryBoxRef &&
          (this.$refs.textEntryBoxRef.textAreaValue ||
            this.$refs.textEntryBoxRef.metatags)
        ) {
          this.$refs.textEntryBoxRef.commitKO(
            this.$refs.textEntryBoxRef.textAreaValue,
            this.$refs.textEntryBoxRef.metatags
          );
          this.isOpenAddingBlock = false;
        }
      },

      updateExistingCanvasAndClear() {
        this.updateCanvas(this.selectCanvasName);
        this.clearTheCanvas();
        this.selectCanvasName = null;
        this.isNewBlankCanvas = true;
      },

      async saveNewCanvas() {
        this.showModalSaveCanvas = false;
        this.idSelectBlankCanvas = "";
        this.selectCanvasName = null;
        this.isEnteringName = false;

        try {
          if (this.currentThoughtId) {
            await this.$store.dispatch(
              "thoughtsStore/deleteLooseThought",
              this.currentThoughtId
            );
            this.$store.commit("thoughtsStore/setCurrentThoughtId", "");
          }

          const canvasName =
            this.inputName || `New Canvas ${this.blankCanvases.length + 1}`;
          const payload = { canvasName, KOs: [] };

          await this.$store.dispatch("canvasStore/saveNewCanvas", payload);
          this.inputName = "";
          const userCanvasId = getItemFromLS("userCanvasId");
          localStorage.removeItem("userCanvasId");
          await this.sendCanvasAfterSavingForUpdate(payload, userCanvasId);
        } catch (err) {
          console.error("Error saving new canvas:", err);
        }
      },

      async sendCanvasAfterSavingForUpdate(payload, userCanvasId) {
        const blankCanvases = [...this.blankCanvases];
        blankCanvases.unshift({ userCanvasId, canvasName: payload.canvasName });
        this.$store.commit("canvasStore/setBlankCanvases", blankCanvases);

        const KOItems = this.KOItems;
        if (KOItems.length) {
          const KOsForUpdate = KOItems.map((el) => ({
            id: el.knowledgeObjectId,
            updateType: "add",
          }));
          filterKOPosition();
          const koLocations = getItemFromLS("koLocations");
          const updatedCanvas = {
            canvasName: payload.canvasName,
            koUpdates: KOsForUpdate,
            koLocations: JSON.stringify(koLocations),
          };
          this.setItemToLS("updatedCanvas", updatedCanvas);

          await this.$store.dispatch("canvasStore/updateCanvas", userCanvasId);
          this.$store.commit("KOStore/setKOItems", []);
        }

        this.$router.push(`/previous-canvas/${userCanvasId}`);
      },

      async updateCanvas(canvasName) {
        try {
          const updatedCanvas = {
            canvasName,
            koUpdates: [],
            koLocations: JSON.stringify(getItemFromLS("koLocations")),
          };
          this.setItemToLS("updatedCanvas", updatedCanvas);
          await this.$store.dispatch(
            "canvasStore/updateCanvas",
            this.idSelectBlankCanvas
          );
        } catch (err) {
          console.error("Error updating canvas:", err);
        }
      },

      async clearTheCanvas() {
        if (this.isNewBlankCanvas) {
          await this.$store.dispatch("KOStore/deleteKOItems", this.KOItems);
        }

        localStorage.removeItem("newCanvas");
        localStorage.removeItem("KOItems");
        localStorage.removeItem("newKO");
        this.$store.commit("KOStore/setKOItems", []);
        this.isOpenAddingBlock = true;
        this.selectCanvasName = null;
      },

      updateKO(itemKO) {
        const itemPosition = getItemFromLS("koLocations").find(
          (el) => el.id === itemKO.knowledgeObjectId
        );

        if (itemPosition && itemPosition.x != null && itemPosition.y != null) {
          this.top = itemPosition.y;
          this.left = itemPosition.x;
          itemKO.left = itemPosition.x;
          itemKO.top = itemPosition.y;
          this.addingBlockPosition = {
            left: convertToPixeles(itemPosition.x + 0.8, 0).x,
            top: convertToPixeles(0, itemPosition.y + 5).y,
          };
        }

        this.KOForUpdate = itemKO;
        this.isOpenAddingBlock = !this.isOpenAddingBlock;

        // Reset KOForUpdate after a delay
        setTimeout(() => {
          this.KOForUpdate = {};
        }, 1000);
      },

      movedKO(newRect) {
        this.top = newRect.y;
        this.left = newRect.x;
        this.movedItem = true;
      },

      dragStop(knowledgeObjectId) {
        const position = this.convertToPercentage(this.left, this.top);
        const updateKO = this.KOItems.find(
          (elem) => String(elem.knowledgeObjectId) === String(knowledgeObjectId)
        );

        if (updateKO && this.isPossibilityUpdateKo && this.movedItem) {
          const newKo = {
            y: position.y,
            x: position.x,
            body: updateKO.body,
            metatags: updateKO.metatags,
          };
          this.setItemToLS("updatedKO", newKo);
          this.updatePositionKo(knowledgeObjectId, newKo);
        }
        this.isPossibilityUpdateKo = false;
        this.movedItem = false;
      },

      async deleteKo(id) {
        if (!this.isNewBlankCanvas) {
          const updatedCanvas = {
            canvasName: this.selectCanvasName,
            koUpdates: [id, "remove"],
            koLocations: JSON.stringify(getItemFromLS("koLocations")),
          };
          this.setItemToLS("updatedCanvas", updatedCanvas);
          await this.$store.dispatch(
            "canvasStore/updateCanvas",
            this.idSelectBlankCanvas
          );
        }
        await this.$store.dispatch("KOStore/deleteKOItem", id);
      },

      toggleNewThought() {
        this.isOpenNewThought = !this.isOpenNewThought;
        if (this.isOpenAddingBlock) this.isOpenAddingBlock = false;
      },

      async showSelectBlankCanvas(canvas) {
        if (this.isNewBlankCanvas && this.KOItems.length) {
          await this.saveNewCanvas();
        }

        if (!this.isNewBlankCanvas) {
          await this.updateCanvas(this.selectCanvasName);
        }

        this.idSelectBlankCanvas = canvas.userCanvasId;
        this.selectCanvasName = canvas.canvasName;

        await this.$store.dispatch(
          "canvasStore/getCanvas",
          this.idSelectBlankCanvas
        );

        this.isNewBlankCanvas = false;
        this.isOpenAddingBlock = false;
        this.isEnteringName = false;
      },

      isCanvasSelected(canvas) {
        return canvas.canvasName === this.selectCanvasName;
      },

      commitKO() {
        console.log("hi");
        let KOs = getItemFromLS("KOItems") || [];
        const updatedCanvas = {
          canvasName: this.selectCanvasName,
          koUpdates: KOs.map((el) => ({
            id: el.knowledgeObjectId,
            updateType: "add",
          })),
          koLocations: JSON.stringify(getItemFromLS("koLocations")),
        };
        this.setItemToLS("updatedCanvas", updatedCanvas);
        this.$store
          .dispatch("canvasStore/updateCanvas", this.idSelectBlankCanvas)
          .then(() => {
            this.$store.dispatch(
              "canvasStore/getCanvas",
              this.idSelectBlankCanvas
            );
          });
      },
    },
    unmounted() {
      this.$store.commit("canvasStore/setBlankCanvases", []);
      this.setItemToLS("koLocations", []);
      this.clearTheCanvas();
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/pages/home/blank-canvas.scss";
</style>

<template>
  <div class="loose-thoughts">
    <div class="loose-thoughts__top-block">
      <button class="top_block__home-btn" type="button" @click="redirectHome">
        Home
      </button>
    </div>
    <img
      class="loose-thoughts__img-top"
      src="/assets/for-items-top.png"
      @click="toggleNewThought"
    />
    <div id="darken-block" v-if="IsOpenNewThought" @click="toggleNewThought" />
    <NewLooseThoughts
      id="testNewLooseThought"
      v-if="IsOpenNewThought"
      :toggle-new-thought="toggleNewThought"
      :isUpdateLooseThought="isUpdateLooseThought"
    />
    <b-overlay :show="stateOverlay" id="loose-thought-body">
      <h2 class="loose-thoughts__title">My Loose Thoughts</h2>
      <ul class="loose-thoughts__list">
        <li
          class="loose-thoughts__list-item"
          v-for="item in looseThoughts"
          :key="item.index"
        >
          <button
            type="button"
            class="list-item__btn-add list-item__btn"
            @click="openInNewCanvas(item)"
          ></button>
          <button
            type="button"
            class="list-item__btn-save list-item__btn"
            @click="commitLooseThoughts(item)"
          ></button>
          <button
            type="button"
            class="list-item__btn-delete list-item-btn"
            @click="deleteLooseThought(item.knowledgeObjectId)"
          >
            <i class="bi bi-trash"></i>
          </button>
          <p class="loose-thoughts__list-item-body">{{ item.body }}</p>
          <ul class="list-item__metatags-list">
            <li
              class="list-item__metatags-list-item"
              v-for="(metatag, mtIndex) in item.metatags"
              :key="mtIndex"
            >
              {{ metatag }}
            </li>
          </ul>
        </li>
      </ul>
    </b-overlay>
  </div>
</template>

<script>
  import { setItemToLS, hexToRGB } from "@/functions";
  import { mapGetters } from "vuex";
  import NewLooseThoughts from "@/components/NewLooseThoughts.vue";

  const COLOR_ARRAY = [
    "#FFC0CB",
    "#FFB6C1",
    "#FF69B4",
    "#FF1493",
    "#DB7093",
    "#C71585",
    "#BA55D3",
    "#9932CC",
    "#9400D3",
    "#8A2BE2",
    "#8B008B",
    "#483D8B",
    "#4B0082",
    "#FFA07A",
    "#FA8072",
    "#E9967A",
    "#CD5C5C",
    "#DC143C",
    "#FF0000",
    "#B22222",
    "#8B0000",
    "#FFA500",
    "#FF8C00",
    "#FF7F50",
    "#FF6347",
    "#FF4500",
    "#FFD700",
    "#FFFF00",
    "#FFFACD",
    "#FFEFD5",
    "#FFE4B5",
    "#FFDAB9",
    "#EEE8AA",
    "#F0E68C",
    "#BDB76B",
    "#ADFF2F",
    "#00FF00",
    "#00FA9A",
    "#00FF7F",
    "#3CB371",
    "#008000",
    "#006400",
    "#9ACD32",
    "#556B2F",
    "#66CDAA",
    "#20B2AA",
    "#008B8B",
    "#00FFFF",
    "#AFEEEE",
    "#7FFFD4",
    "#40E0D0",
    "#00CED1",
    "#5F9EA0",
    "#4682B4",
    "#87CEFA",
    "#6495ED",
    "#00BFFF",
    "#1E90FF",
    "#4169E1",
    "#0000FF",
    "#00008B",
    "#808080",
    "#778899",
    "#2F4F4F",
    "#000000",
  ];

  export default {
    name: "LooseThoughts",
    data() {
      return {
        IsOpenNewThought: false,
        isUpdateLooseThought: false,
      };
    },
    computed: {
      ...mapGetters({
        looseThoughts: "thoughtsStore/looseThoughts",
        stateOverlay: "thoughtsStore/stateOverlayForLT",
      }),
    },
    components: {
      NewLooseThoughts,
    },
    watch: {
      looseThoughts() {
        this.$nextTick(() => {
          this.applyRandomColors();
          this.editStyleOnResizeWindow();
        });
      },
    },
    mounted() {
      window.addEventListener("resize", this.editStyleOnResizeWindow);
      this.$store.commit("thoughtsStore/setLooseThought", []);
      this.$store.dispatch("thoughtsStore/getLooseThoughts").then(() => {
        this.applyRandomColors();
        this.editStyleOnResizeWindow();
      });
    },
    unmounted() {
      window.removeEventListener("resize", this.editStyleOnResizeWindow);
      this.$store.commit("thoughtsStore/setLooseThought", []);
    },
    methods: {
      redirectHome() {
        this.$router.push(`/`);
      },
      commitLooseThoughts(item) {
        this.isUpdateLooseThought = true;
        setItemToLS("updateThought", item);
        this.IsOpenNewThought = true;
        setTimeout(() => {
          this.isUpdateLooseThought = false;
        }, 1000);
      },
      applyRandomColors() {
        const metatags = document.getElementsByClassName(
          "list-item__metatags-list-item"
        );

        for (let i = 0; i < metatags.length; i++) {
          const el = metatags[i];
          const color = COLOR_ARRAY[i % COLOR_ARRAY.length];
          el.style.backgroundColor = color;
          const rgb = hexToRGB(color);

          if (
            1 - (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255 <
            0.25
          ) {
            el.style.color = "black";
          } else {
            el.style.color = "";
          }
        }
      },
      openInNewCanvas(item) {
        setItemToLS("newCanvas", item);
        this.$store.commit("canvasStore/setNewCanvas", item);
        this.$store.commit(
          "thoughtsStore/setCurrentThoughtId",
          item.knowledgeObjectId
        );
        this.$router.push(`/blank-canvas`);
      },
      toggleNewThought() {
        this.IsOpenNewThought = !this.IsOpenNewThought;
      },
      deleteLooseThought(id) {
        this.$store.dispatch("thoughtsStore/deleteLooseThought", id);
      },
      editStyleOnResizeWindow() {
        const looseThoughtBody = document.getElementById("loose-thought-body");
        if (!looseThoughtBody) return;
        const padding = window.innerWidth >= 1500 ? 59 : 43;
        looseThoughtBody.style.height = `calc(100vh - ${padding}px)`;
        looseThoughtBody.style.top = `${padding}px`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/pages/home/loose-thoughts.scss";
</style>

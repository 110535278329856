<template>
  <div id="main-adding-block">
    <button
      type="button"
      class="main-adding-block__add-hash main-adding-block__btn"
      v-on:click="toggleHashBox"
    ></button>
    <textarea
      v-model="textAreaValue"
      name="textarea"
      id="textarea-main"
      col="30"
      rows="5"
    ></textarea>
    <div v-if="isLoading" class="loader"></div>
    <button
      type="button"
      class="main-adding-block__save main-adding-block__btn"
      v-on:click="commitKO(textAreaValue, metatags)"
    ></button>

    <div v-if="isOpenHashBox" class="hash-box">
      <div id="hash-box-icon">#</div>
      <input
        v-model="hashInputValue"
        type="text"
        id="hash-box-input"
        v-on:input="validationMetatags"
        v-on:change="saveHashInfo"
        v-on:blur="saveHashInfo"
        v-on:keydown="saveHashtagOnKeypress"
      />
      <ul class="hash-list">
        <li
          class="hash-list-item"
          v-for="hash in metatags"
          :key="hash.index"
          @click="updateMetatag(hash)"
        >
          {{ hash }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {
    convertToPercentage,
    convertToPixeles,
    getItemFromLS,
    getValidateHashValue,
    setItemToLS,
    validateHashTag,
  } from "@/functions";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        isOpenHashBox: false,
        textAreaValue: "",
        hashInputValue: "",
        metatags: [],
        knowledgeObjectIdForUpdate: "",
        positionTextEntryBox: {},
        canvasInterval: null,
        isLoading: false,
      };
    },
    props: {
      isCommitKO: Boolean,
      KOForUpdate: Object,
      type: String,
      previousCanvasId: String,
      addingBlockPosition: Object,
    },
    beforeMount() {
      let KOForNewCanvas = getItemFromLS("newCanvas");
      if (this.KOForUpdate.knowledgeObjectId) {
        this.metatags = this.KOForUpdate.metatags;
        this.textAreaValue = this.KOForUpdate.body;
        this.knowledgeObjectIdForUpdate = this.KOForUpdate.knowledgeObjectId;
      } else {
        if (KOForNewCanvas) {
          this.textAreaValue = KOForNewCanvas.body;
          this.metatags = KOForNewCanvas.metatags;
        }
      }
    },
    mounted() {
      const newCanvas = this.getItemFromLS("newCanvas");
      this.setPositionEntryBox();
      if (newCanvas && (newCanvas.body || newCanvas.metatags)) {
        this.textAreaValue
          ? (this.textAreaValue = newCanvas.body)
          : (this.textAreaValue = "");
        this.metatags
          ? (this.metatags = newCanvas.metatags)
          : (this.metatags = []);
        this.isOpenHashBox = true;
      }
      this.canvasInterval = setInterval(() => {
        if (
          (this.isCommitKO && this.textAreaValue) ||
          (this.isCommitKO && this.metatags.length)
        )
          this.commitKO(this.textAreaValue, this.metatags);
      }, 30000);
      this.$nextTick(() => {
        const textAreaMain = document.getElementById("textarea-main");
        if (textAreaMain) {
          textAreaMain.focus();
        }
      });
    },
    beforeUnmount() {
      if (
        (this.textAreaValue || (this.metatags && this.metatags.length)) &&
        this.hashInputValue !== "#"
      ) {
        // this.commitKO(this.textAreaValue, this.metatags);
      }
      clearInterval(this.canvasInterval);
    },
    computed: {
      ...mapGetters({
        storeNewCanvas: "canvasStore/newCanvas",
      }),
    },
    watch: {
      isCommitKO() {
        if (
          (this.isCommitKO && this.textAreaValue) ||
          (this.isCommitKO && this.metatags.length)
        )
          this.commitKO(this.textAreaValue, this.metatags);
      },
      addingBlockPosition: {
        handler(newVal, oldVal) {
          this.$nextTick(() => {
            this.setPositionEntryBox();
            const textAreaMain = document.getElementById("textarea-main");

            if (textAreaMain && !this.isOpenHashBox) {
              textAreaMain.focus();
            }
          });
        },
        deep: true,
        immediate: true,
      },
      KOForUpdate() {
        if (Object.keys(this.KOForUpdate).includes("knowledgeObjectId")) {
          this.textAreaValue = this.KOForUpdate.body;
          (this.metatags = this.KOForUpdate.metatags),
            (this.knowledgeObjectIdForUpdate =
              this.KOForUpdate.knowledgeObjectId);
          this.isOpenHashBox = true;
        }
      },
    },
    methods: {
      getItemFromLS,
      convertToPixeles,
      getValidateHashValue,
      convertToPercentage,
      toggleHashBox() {
        this.isOpenHashBox = !this.isOpenHashBox;
        this.$nextTick(() => {
          if (this.isOpenHashBox) {
            const hashInput = document.getElementById("hash-box-input");
            if (hashInput) {
              hashInput.focus();
            }
          }
        });
      },

      validationMetatags(event) {
        let hashInput = document.getElementById("hash-box-input");
        validateHashTag(event, hashInput);
      },
      saveHashInfo() {
        let hashInput = document.getElementById("hash-box-input");
        if (!hashInput.classList.contains("invalid-input")) {
          this.hashInputValue = "#" + this.hashInputValue;
          if (this.hashInputValue.includes(" ")) {
            let updatedString = getValidateHashValue(this.hashInputValue);
            if (!this.metatags.includes(updatedString))
              this.metatags.push(updatedString);
          } else {
            if (!this.metatags) this.metatags = [];
            if (this.hashInputValue !== "#")
              if (!this.metatags.includes(this.hashInputValue))
                this.metatags.push(this.hashInputValue);
          }
        }
        this.hashInputValue = "";
      },
      setPositionEntryBox() {
        const addingBlock = document.getElementById("main-adding-block");
        const pageWidth = document.body.clientWidth;
        const pageHeight = document.body.clientHeight;

        if (this.addingBlockPosition.top && this.addingBlockPosition.left) {
          if (
            this.addingBlockPosition.top <
            pageHeight - (addingBlock.clientWidth + 100)
          ) {
            this.positionTextEntryBox.top = this.addingBlockPosition.top;
          } else {
            this.positionTextEntryBox.top =
              pageHeight - (addingBlock.clientWidth + 100);
          }

          if (
            this.addingBlockPosition.left <
            pageWidth - (addingBlock.clientWidth + 350)
          ) {
            this.positionTextEntryBox.left = this.addingBlockPosition.left;
          } else {
            this.positionTextEntryBox.left =
              pageWidth - (addingBlock.clientWidth + 350);
          }

          if (pageWidth < 576) {
            if (
              this.addingBlockPosition.left <
              pageWidth - (addingBlock.clientWidth + 200)
            ) {
              this.positionTextEntryBox.left = this.addingBlockPosition.left;
            } else {
              this.positionTextEntryBox.left =
                pageWidth - (addingBlock.clientWidth + 200);
            }
          }

          addingBlock.style.top = `${this.positionTextEntryBox.top}px`;
          addingBlock.style.left = `${this.positionTextEntryBox.left}px`;
        } else {
          this.positionTextEntryBox.top = 100;
          this.positionTextEntryBox.left = 100;
          addingBlock.style.top = `${this.positionTextEntryBox.top}px`;
          addingBlock.style.left = `${this.positionTextEntryBox.left}px`;
        }
      },
      commitKO(textAreaValue, metatags) {
        if (!textAreaValue && !metatags) {
          return;
        }

        let offsetPositionY = 9;
        let offsetPositionX = 1;

        if (this.$route.name === "PreviousCanvas") {
          offsetPositionY += 10.5;
        }

        const newKO = {
          body: textAreaValue,
          metatags,
          top:
            convertToPercentage(0, this.positionTextEntryBox.top + 60).y -
            offsetPositionY,
          left:
            convertToPercentage(this.positionTextEntryBox.left, 0).x -
            offsetPositionX,
        };

        if (this.knowledgeObjectIdForUpdate) {
          setItemToLS("updatedKO", newKO);
          let payload = {
            id: this.knowledgeObjectIdForUpdate,
            type: this.type,
            previousCanvasId: this.previousCanvasId,
          };
          this.$store.dispatch("KOStore/updateKOItem", payload);
        } else {
          if (newKO.body || newKO.metatags.length) {
            setItemToLS("newKO", newKO);
            this.$store.dispatch("KOStore/saveNewKOItem", { type: this.type });
          }
        }
        this.textAreaValue = "";
        this.hashInputValue = "";
        this.metatags = [];
        this.knowledgeObjectIdForUpdate = "";

        if (this.storeNewCanvas !== null) {
          this.$store.commit("canvasStore/setNewCanvas", {});
          setItemToLS("newCanvas", {});
        }
      },
      updateMetatag(hash) {
        if (this.knowledgeObjectIdForUpdate) {
          this.saveHashInfo();
          this.hashInputValue = hash.substring(1);
          const hashIndex = this.metatags.indexOf(hash);
          this.metatags.splice(hashIndex, 1);
        }
      },
      saveHashtagOnKeypress(e) {
        if (`${e.key}` === "Enter") this.saveHashInfo();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/components/text-entryBox.scss";
</style>

<template>
  <div class="background">
    <section class="main-container">
      <aside class="branding">
        <img src="/assets/logo.png" alt="logo" class="logo" />
        <h2 class="title">LocusVMS</h2>
        <p class="description short">Venue Management System</p>
        <p class="description long">
          A powerful, yet easy-to-use application for managing venue geospatial
          data.
        </p>
      </aside>

      <section class="form-container">
        <h3 class="form-title">Confirm Account</h3>
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label for="confirm-code">Verification Code</label>
            <input
              id="confirm-code"
              type="text"
              class="input-field"
              v-model="code"
            />
          </div>
          <button
            type="submit"
            class="submit-button"
            :disabled="loading || storeLoading"
          >
            <div v-if="loading || storeLoading" class="loading-spinner"></div>
            <span v-else>Submit</span>
          </button>
        </form>

        <div v-if="showError" class="error">
          {{ errmsg }}
        </div>
        <div class="divider"></div>
      </section>
    </section>
  </div>
</template>

<script>
  import * as config from "@/config";
  import { mapGetters } from "vuex";
  const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

  export default {
    name: "ConfirmEmail",
    data() {
      return {
        confirmed: false,
        showError: false,
        errmsg: "",
        code: "",
        loading: false,
      };
    },
    computed: {
      ...mapGetters({
        storeLoading: "authStore/loading",
        storeUserData: "authStore/userData",
      }),
    },
    methods: {
      onSubmit() {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(
          config.poolData
        );
        const username = this.storeUserData.email;

        if (!username) {
          this.showError = true;
          this.errmsg = "Username does not exist. Please sign up again.";
          return;
        }

        this.loading = true;
        this.showError = false;

        const userData = {
          Username: username,
          Pool: userPool,
        };

        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.confirmRegistration(this.code, true, (err, result) => {
          this.loading = false;
          if (err) {
            console.error("Confirmation error:", err);
            this.errmsg = this.getErrorMessage(err.code);
            this.showError = true;
          } else {
            console.log("Confirmation success:", result);
            this.confirmed = true;
          }
        });
      },
      getErrorMessage(code) {
        switch (code) {
          case "CodeMismatchException":
            return "Invalid verification code provided";
          case "NotAuthorizedException":
            return "The user has already been confirmed";
          case "UserNotFoundException":
            return "Username (email) not found!";
          case "LimitExceededException":
            return "Attempt limit exceeded, please try after some time";
          case "ExpiredCodeException":
            return "Invalid code provided, please request a new code";
          default:
            return "An error has occurred!";
        }
      },
    },
    watch: {
      confirmed(newVal) {
        if (newVal === true) {
          this.$store.dispatch("authStore/signIn");
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/pages/auth/confirm-email.scss";
</style>
